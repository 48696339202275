import { useEffect } from 'react'
import { createNanoEvents } from 'nanoevents'
import { IBlockDataSource } from '@koii/interfaces'

export type Events = {
  appOpened: () => void
  pageOpened: (name: string) => void
  toggleSoundClicked: () => void
  pageVisibilityChanged: () => void
  mouseEnteredBlock: (url: string) => void
  mouseLeftBlock: (url: string) => void
  mouseClicked3dBlock: (url: string) => void
  mouseEntered3dBlock: () => void
  mouseLeft3dBlock: () => void
  stageComponentLoaded: (pages: IBlockDataSource[]) => void
}

export const eventBus = createNanoEvents<Events>()

export function useEvent (event: keyof Events, handler: any) {
  useEffect(() => {
    const unsubscriber = eventBus.on(event as keyof Events, handler)

    return () => {
      unsubscriber()
    }
    // eslint-disable-next-line
  }, [])
}
