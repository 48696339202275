import * as THREE from 'three'
import gsap from 'gsap'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import terrainVertexShader from './shaders/terrain/vertex.glsl'
import terrainFragmentShader from './shaders/terrain/fragment.glsl'
import dat from 'dat.gui'
import { Fog } from 'three'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import Stats from 'stats.js'
import { ISpacedPageDescriptors } from '@koii/interfaces'

const debugObject = {
  depthColor: '#2e9fdb',
  surfaceColor: '#070914',
  fogColor: '#182832',
  blockColor: '#9BE7C4',
  dimmedBlockColor: '#6990ca',
  selectedBlockColor: '#d78da6',
  bloomThreshold: 0.49653,
  bloomStrength: 0.738,
  bloomRadius: 0.847,
  blocksY: 0.516,
  exposure: 1
}

const textureLoader = new THREE.TextureLoader()

type BlockClickListener = (name: string) => void
type BlockHoverListener = () => void

type TBlock = THREE.Mesh<THREE.BoxGeometry, THREE.MeshBasicMaterial>
export class Stage {
  private scene: THREE.Scene
  private gui?: dat.GUI
  private stats?: Stats
  private fog: Fog
  private blocksGroup: THREE.Group
  private blocksWireframeGroup: THREE.Group
  private terrain: THREE.Mesh
  private camera: THREE.PerspectiveCamera
  private renderer: THREE.WebGLRenderer
  private effectComposer: EffectComposer
  private controls: OrbitControls
  private blockClickListener: BlockClickListener
  private blockMouseEnterListener: BlockHoverListener
  private blockMouseLeftListener: BlockHoverListener
  private activeBlockName?: string
  private selectedBlock?: TBlock
  private hoveredBlock: TBlock
  private wireframedBlock: TBlock
  private sizes = {
    width: window.innerWidth,
    height: window.innerHeight
  }
  private raycaster: THREE.Raycaster
  private wasMouseMoved: boolean
  private wasSceneClicked: boolean
  private mousePosition: THREE.Vector2

  constructor (
    private canvas: HTMLCanvasElement,
    private nodes: ISpacedPageDescriptors,
    private debugMode?: boolean
  ) {
    this.wasMouseMoved = false
    this.wasSceneClicked = false
    this.mousePosition = new THREE.Vector2(0, 0)

    this.raycaster = new THREE.Raycaster()

    this.scene = new THREE.Scene()

    this.setupDevTools()

    // Setup fog
    this.generateFog()

    this.scene.background = new THREE.Color(debugObject.fogColor)

    // Setup Blocks
    this.generateBlocksGroup(this.nodes)
    this.generateEasterEgg()

    // Setup terrain
    this.terrain = this.generateTerrain()
    this.terrain.rotation.x = -Math.PI * 0.5
    this.scene.add(this.terrain)

    this.generateCamera()

    // Setup controls
    this.setupControls()

    // Setup renderer
    this.setupRenderer()

    // Setup postprocessing
    this.setupPostproccessing()

    this.attachListeners()

    const clock = new THREE.Clock()

    const tick = () => {
      if (this.debugMode) {
        this.stats.begin()
      }

      const elapsedTime = clock.getElapsedTime()
      const terrainMaterial = this.terrain.material as THREE.ShaderMaterial
      terrainMaterial.uniforms.uTime.value = elapsedTime

      this.controls.update()
      this.effectComposer.render()

      // Handle mouse events only when update occurs
      if (this.wasSceneClicked) {
        this.sceneWasClickedHandler()
      }

      if (this.wasMouseMoved) {
        this.mouseWasMovedHandler()
      }

      if (this.debugMode) {
        this.stats.end()
      }

      window.requestAnimationFrame(tick)
    }

    tick()

    this.showGridHelper()
  }

  private generateEasterEgg () {
    const easterEggGroup = new THREE.Group()

    easterEggGroup.position.set(0, -2, 0)
    easterEggGroup.rotation.x = Math.PI * 0.5
    easterEggGroup.rotation.y = -Math.PI

    this.scene.add(easterEggGroup)

    new THREE.TextureLoader().loadAsync('easter-egg.png').then(map => {
      const { width, height } = map.image
      const material = new THREE.SpriteMaterial({ map: map })
      const sprite = new THREE.Sprite(material)
      sprite.geometry.scale(width / 400, height / 400, 1)
      easterEggGroup.add(sprite)
    })
  }

  private setupPostproccessing () {
    this.effectComposer = new EffectComposer(this.renderer)
    this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    this.effectComposer.setSize(this.sizes.width, this.sizes.height)

    const renderPass = new RenderPass(this.scene, this.camera)
    this.effectComposer.addPass(renderPass)

    const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(this.sizes.width, this.sizes.height),
      debugObject.bloomStrength,
      debugObject.bloomRadius,
      debugObject.bloomThreshold
    )
    this.effectComposer.addPass(bloomPass)

    if (this.debugMode) {
      this.gui
        .add(debugObject, 'bloomThreshold')
        .min(0)
        .max(1)
        .step(0.00001)
        .onChange(function (value) {
          bloomPass.threshold = Number(value)
        })

      this.gui
        .add(debugObject, 'bloomStrength')
        .min(0)
        .max(1)
        .step(0.0001)
        .onChange(function (value) {
          bloomPass.strength = Number(value)
        })

      this.gui
        .add(debugObject, 'bloomRadius')
        .min(0)
        .max(10)
        .step(0.001)
        .onChange(function (value) {
          bloomPass.radius = Number(value)
        })

      this.gui.add(debugObject, 'exposure', 0.1, 2).onChange(value => {
        this.renderer.toneMappingExposure = Math.pow(value, 4.0)
      })
    }
  }

  private setupRenderer () {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas
    })
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  }

  private setupDevTools () {
    if (this.debugMode) {
      const gui = new dat.GUI({ closed: true })
      this.gui = gui

      this.stats = new Stats()
      this.stats.showPanel(0)

      document.body.appendChild(this.stats.dom)
    }
  }

  private generateBlocksGroup (nodes: ISpacedPageDescriptors) {
    const geometry = new THREE.BoxGeometry(0.15, 0.15, 0.15)
    this.blocksGroup = new THREE.Group()
    this.blocksWireframeGroup = new THREE.Group()

    for (const node of nodes) {
      const material = new THREE.MeshBasicMaterial({
        color: debugObject.blockColor
      })
      const box = new THREE.Mesh(geometry, material)
      box.position.set(node.position.x, 0, node.position.y)
      box.userData.name = node.payload.name
      this.blocksGroup.add(box)
    }

    const material = new THREE.MeshBasicMaterial({
      color: debugObject.blockColor,
      wireframe: true
    })

    this.wireframedBlock = new THREE.Mesh(geometry, material)

    const firstBlock = this.blocksGroup.children[0]

    if (firstBlock) {
      this.wireframedBlock.position.set(
        firstBlock.position.x,
        0,
        this.blocksGroup.children[0].position.z
      )
      this.blocksWireframeGroup.add(this.wireframedBlock)
      this.blocksWireframeGroup.position.set(0, debugObject.blocksY, 0)
      this.scene.add(this.blocksWireframeGroup)
    }

    this.blocksGroup.position.set(0, debugObject.blocksY, 0)
    this.scene.add(this.blocksGroup)

    if (this.debugMode) {
      this.gui.addColor(debugObject, 'blockColor').onChange(() => {
        this.blocksGroup.children.forEach((block: TBlock) =>
          block.material.color.set(debugObject.blockColor)
        )
      })
      this.gui.addColor(debugObject, 'selectedBlockColor').onChange(() => {
        this.selectedBlock.material.color.set(debugObject.selectedBlockColor)
      })
    }
  }

  private generateTerrain () {
    const terrainGeometry = new THREE.PlaneGeometry(10, 10, 512, 512)
    const terrainMaterial = new THREE.ShaderMaterial({
      vertexShader: terrainVertexShader,
      fragmentShader: terrainFragmentShader,
      fog: true,
      wireframe: false,
      side: THREE.DoubleSide,
      uniforms: {
        uTime: { value: 0 },
        uHeightmap: { value: null },
        fogColor: { value: this.fog.color },
        fogNear: { value: this.fog.near },
        fogFar: { value: this.fog.far },

        uElevationMultiplier: { value: 0 },

        uDepthColor: { value: new THREE.Color(debugObject.depthColor) },
        uSurfaceColor: { value: new THREE.Color(debugObject.surfaceColor) },
        uColorOffset: { value: 0.08 },
        uColorMultiplier: { value: 1.064 }
      }
    })

    const pointsHeightmap = textureLoader.load('./heightmap.png', () => {
      gsap.to(terrainMaterial.uniforms.uElevationMultiplier, {
        value: 2,
        duration: 1
      })
    })

    terrainMaterial.uniforms.uHeightmap.value = pointsHeightmap

    if (this.debugMode) {
      this.gui.addColor(debugObject, 'depthColor').onChange(() => {
        terrainMaterial.uniforms.uDepthColor.value.set(debugObject.depthColor)
      })
      this.gui.addColor(debugObject, 'surfaceColor').onChange(() => {
        terrainMaterial.uniforms.uSurfaceColor.value.set(
          debugObject.surfaceColor
        )
      })

      this.gui
        .add(terrainMaterial.uniforms.uElevationMultiplier, 'value')
        .min(0)
        .max(10)
        .step(0.001)
        .name('Elevation Multiplier')

      this.gui
        .add(debugObject, 'blocksY')
        .min(0)
        .max(2)
        .step(0.001)
        .name('Blocks Y')
        .onChange(value => {
          this.blocksGroup.position.y = Number(value)
        })

      this.gui
        .add(terrainMaterial.uniforms.uColorOffset, 'value')
        .min(0)
        .max(1)
        .step(0.001)
        .name('uColorOffset')
      this.gui
        .add(terrainMaterial.uniforms.uColorMultiplier, 'value')
        .min(0)
        .max(10)
        .step(0.001)
        .name('uColorMultiplier')
    }

    return new THREE.Mesh(terrainGeometry, terrainMaterial)
  }

  private generateCamera () {
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.sizes.width / this.sizes.height,
      0.1,
      100
    )
    this.camera.position.set(
      0.5361832684635387,
      2.8834406888307456,
      -2.961460242520865
    )

    const cameraDebug = {
      getCameraPosition: () => {
        console.log(`Camera ${this.camera.name} positon:`)
        console.log(this.camera.position.toArray())
      },
      setBirdView: () => {
        this.camera.position.set(
          -0.3154475424957421,
          4.054284130212215,
          1.5878661042545004
        )
        this.camera.lookAt(new THREE.Vector3(0, 0, 0))
      }
    }

    if (this.debugMode) {
      const folder = this.gui.addFolder('Camera')
      folder.add(cameraDebug, 'getCameraPosition').name('Log position')
      folder.add(cameraDebug, 'setBirdView').name('Set bird view')
      folder.open()

      const positionFolder = folder.addFolder('Position')
      positionFolder.open()
      positionFolder
        .add(this.camera.position, 'x')
        .min(-10)
        .max(10)
        .step(0.5)
        .listen()
      positionFolder
        .add(this.camera.position, 'y')
        .min(-10)
        .max(10)
        .step(0.5)
        .listen()
      positionFolder
        .add(this.camera.position, 'z')
        .min(-10)
        .max(10)
        .step(0.5)
        .listen()
    }
  }

  private setupControls () {
    this.controls = new OrbitControls(this.camera, this.canvas)
    this.controls.enableDamping = true

    if (!this.debugMode) {
      this.controls.maxPolarAngle = 1.175654642115765
      this.controls.minDistance = 1.6104644713294745
      this.controls.maxDistance = 4.0543968243149635
    }
  }

  private showGridHelper () {
    if (this.debugMode) {
      const size = 10
      const divisions = 10

      const gridHelper = new THREE.GridHelper(size, divisions, 'red', 'lime')
      this.scene.add(gridHelper)

      this.gui.add(gridHelper, 'visible').name('Grid Helper')
    }
  }

  private generateFog () {
    const color = debugObject.fogColor
    const near = 0.3
    const far = 5.2
    const fogDebug = {
      visible: true
    }

    this.fog = new THREE.Fog(color, near, far)
    this.scene.fog = this.fog

    if (this.debugMode) {
      const folder = this.gui.addFolder('Fog')
      folder
        .add(fogDebug, 'visible')
        .name('visible')
        .onChange(visible => {
          if (visible) {
            this.scene.fog = this.fog
          } else {
            this.scene.fog = null
          }
        })
      folder
        .add(this.fog, 'near')
        .min(0)
        .max(20)
      folder
        .add(this.fog, 'far')
        .min(0)
        .max(20)

      folder.addColor(debugObject, 'fogColor').onChange(() => {
        this.fog.color.set(debugObject.fogColor)
        this.scene.background = new THREE.Color(debugObject.fogColor)
      })
    }
  }

  private attachListeners () {
    this.blockClickListener = () =>
      // implement handler{}
      (this.blockMouseEnterListener = () => {
        // implement handler
      })
    this.blockMouseLeftListener = () => {
      // implement handler
    }
    this.handleWindowResize()
    this.handleStageMouseMove()
    this.handleStageClick()
  }

  private handleWindowResize () {
    window.addEventListener('resize', () => {
      this.sizes.width = window.innerWidth
      this.sizes.height = window.innerHeight

      this.camera.aspect = this.sizes.width / this.sizes.height
      this.camera.updateProjectionMatrix()

      this.renderer.setSize(this.sizes.width, this.sizes.height)
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })
  }

  private handleStageMouseMove () {
    this.renderer.domElement.addEventListener(
      'mousemove',
      (e: MouseEvent) => {
        this.mousePosition.x = (e.clientX / window.innerWidth) * 2 - 1
        this.mousePosition.y = -(e.clientY / window.innerHeight) * 2 + 1
        this.wasMouseMoved = true
      },
      false
    )
  }

  private mouseWasMovedHandler () {
    this.wasMouseMoved = false
    // Setup raycaster
    this.raycaster.setFromCamera(this.mousePosition, this.camera)

    // Get ray intersections checking only blocks
    const intersects = this.raycaster.intersectObjects(
      this.blocksGroup.children
    )

    if (intersects.length > 0) {
      const hoveredBlock = intersects[0].object as TBlock
      const newBlockIsHovered = this.hoveredBlock?.id !== hoveredBlock.id

      if (newBlockIsHovered) {
        if (this.hoveredBlock) {
          gsap.to(this.hoveredBlock.scale, {
            x: 1.0,
            y: 1.0,
            z: 1.0,
            duration: 0.5
          })
        }

        gsap.to(hoveredBlock.scale, {
          x: 1.1,
          y: 1.1,
          z: 1.1,
          duration: 0.3
        })

        this.showWireframedBlock()
        this.moveWireframedBlockToBlock(hoveredBlock)

        this.blockMouseEnterListener()
      }

      this.hoveredBlock = hoveredBlock
    } else {
      if (this.hoveredBlock) {
        gsap.to(this.hoveredBlock.scale, {
          x: 1.0,
          y: 1.0,
          z: 1.0,
          duration: 0.3
        })
        this.hideWireframedBlock()
        this.hoveredBlock = null
      }

      this.blockMouseLeftListener()
    }
  }

  private showWireframedBlock () {
    gsap.to(this.wireframedBlock.scale, {
      x: 1.4,
      y: 1.4,
      z: 1.4,
      duration: 0.3
    })
  }

  private hideWireframedBlock () {
    gsap.to(this.wireframedBlock.scale, {
      x: 0.9,
      y: 0.9,
      z: 0.9,
      duration: 0.3
    })
  }

  private moveWireframedBlockToBlock (block: TBlock) {
    gsap.to(this.wireframedBlock.position, {
      x: block.position.x,
      y: block.position.y,
      z: block.position.z,
      duration: 0.5,
      ease: 'back.inOut(1.7)'
    })
  }

  private handleStageClick () {
    this.renderer.domElement.addEventListener(
      'click',
      (e: MouseEvent) => {
        this.mousePosition.x = (e.clientX / window.innerWidth) * 2 - 1
        this.mousePosition.y = -(e.clientY / window.innerHeight) * 2 + 1
        this.wasSceneClicked = true
      },
      false
    )
  }

  private sceneWasClickedHandler () {
    this.wasSceneClicked = false

    // Setup raycaster
    this.raycaster.setFromCamera(this.mousePosition, this.camera)

    // Get ray intersections checking only blocks
    const intersects = this.raycaster.intersectObjects(
      this.blocksGroup.children
    )

    if (intersects.length > 0) {
      // Get the nearest clicked object
      const clickedBlock = intersects[0].object as TBlock

      this.blockClickListener(clickedBlock.userData.name)
      this.setSelectedBlock(clickedBlock)
    }
  }

  private findBlockByName (name: string): TBlock | undefined {
    return this.blocksGroup.children.find((block: TBlock) => {
      return block.userData.name === name
    }) as TBlock | undefined
  }

  private setSelectedBlock (block?: TBlock) {
    if (block?.id !== this.selectedBlock?.id) {
      if (this.selectedBlock) {
        const color = new THREE.Color(debugObject.blockColor)
        gsap.to(this.selectedBlock.material.color, {
          r: color.r,
          g: color.g,
          b: color.b,
          duration: 0.3
        })
      }

      this.selectedBlock = block
      const color = new THREE.Color(debugObject.selectedBlockColor)
      gsap.to(this.selectedBlock.material.color, {
        r: color.r,
        g: color.g,
        b: color.b,
        duration: 0.3
      })
    }
  }

  setActiveBlock (name: string) {
    // Do nothing if block is already selected
    if (name !== this.activeBlockName) {
      const activeBlock = this.findBlockByName(name)

      if (activeBlock) {
        this.showWireframedBlock()
        this.moveWireframedBlockToBlock(activeBlock)
      } else {
        this.hideWireframedBlock()
      }

      this.activeBlockName = name
    }
  }

  setSelectedBlockByName (name: string) {
    const block = this.findBlockByName(name)
    this.setSelectedBlock(block)
  }

  onBlockClick (listener: BlockClickListener) {
    this.blockClickListener = listener
  }

  onBlockMouseEnter (listener: BlockHoverListener) {
    this.blockMouseEnterListener = listener
  }

  onBlockMouseLeft (listener: BlockHoverListener) {
    this.blockMouseLeftListener = listener
  }
}
