import { eventBus } from '../../../app/events'
import { useStore } from '../../../app/store'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Breadcrumb from '../breadcrumb/Breadcrumb'
import { breakpoints } from '../breakpoints'
import Icon from '../icon/Icon'
import Sound from '../sound/Sound'
import { Media } from '../../Media'
import { Levels } from '../levels/Levels'
import Box from '../box/Box'
import { ICrumb } from '@koii/interfaces'
import { useTranslation } from 'react-i18next'

interface IHeaderProps {
  crumbs: ICrumb[]
  currentLevel: number
  showExploreLink: boolean
  showExitLink: boolean
}

const Wrapper = styled.header`
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  height: auto;
  pointer-events: auto;

  &.wide-padding {
    padding: 40px;
    height: 60px;
  }
`

const ExploreLink = styled.span`
  display: inline-flex;
  align-items: center;
  white-space: pre;
  color: ${props => props.theme.colors.lightGreen};
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  transition: color 0.3s;

  svg {
    margin-left: 14px;
    width: 32px;
    fill: transparent;
    transition: all 0.3s;
  }

  &:hover {
    svg {
      color: ${props => props.theme.colors.darkBlue};
      fill: ${props => props.theme.colors.lightGreen};
    }
  }
`

const ItemsRight = styled.div`
  display: none;

  ${breakpoints.lg} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const Logo = styled.div`
  height: 32px;
  margin-right: 17px;

  ${breakpoints.lg} {
    height: 46px;
    margin-right: 72px;
  }
`

const SoundWrapper = styled.span`
  margin-left: 40px;
`
const maxLevel = 3

export function Header (props: IHeaderProps) {
  const [{ soundEnabled }] = useStore()
  const { currentLevel } = props
  const { t } = useTranslation()

  const showBreadcrumbs = currentLevel > 0
  const showLevels = currentLevel > 0

  return (
    <>
      <Media lessThan='md'>
        <Wrapper>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
          >
            <Box display='flex' flexDirection='row'>
              <Logo>
                <Link to='/'>
                  <Icon name='logo' color='white' />
                </Link>
              </Logo>
              {showLevels && (
                <Levels currentLevel={props.currentLevel} maxLevel={maxLevel} />
              )}
            </Box>
            {showBreadcrumbs && (
              <Breadcrumb crumbs={props.crumbs} direction='column' />
            )}
          </Box>
        </Wrapper>
      </Media>
      <Media greaterThanOrEqual='md'>
        <Wrapper className='wide-padding'>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            flexGrow={1}
            flexShrink={1}
            flexBasis='auto'
            minWidth={0}
          >
            <Logo>
              <Link to='/'>
                <Icon name='logo' color='white' />
              </Link>
            </Logo>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='flex-start'
              flexGrow={1}
              flexShrink={1}
              flexBasis='auto'
              minWidth={0}
            >
              {showLevels && (
                <Levels currentLevel={props.currentLevel} maxLevel={maxLevel} />
              )}
              {showBreadcrumbs && (
                <Breadcrumb crumbs={props.crumbs} direction='row' />
              )}
            </Box>
          </Box>
          <ItemsRight>
            {props.showExploreLink && (
              <Link to='/explore'>
                <ExploreLink>
                  {t('ui.common.exploreKoii')}{' '}
                  <Icon name='arrow-right-circle' />
                </ExploreLink>
              </Link>
            )}
            {props.showExitLink && (
              <Link to='/'>
                <ExploreLink>
                  {t('ui.common.homePage')} <Icon name='arrow-right-circle' />
                </ExploreLink>
              </Link>
            )}
            <SoundWrapper>
              <Sound
                enabled={soundEnabled}
                onClick={() => eventBus.emit('toggleSoundClicked')}
              />
            </SoundWrapper>
          </ItemsRight>
        </Wrapper>
      </Media>
    </>
  )
}

export default Header
