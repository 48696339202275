import React from 'react'
import styled from 'styled-components'
import { ColorName } from '../theme/Theme'

import { ReactComponent as Logo } from './icons/logo.svg'
import { ReactComponent as ArrowUp } from './icons/arrow-up.svg'
import { ReactComponent as ArrowUpFlat } from './icons/arrow-up-flat.svg'
import { ReactComponent as ArrowDown } from './icons/arrow-down.svg'
import { ReactComponent as ArrowDownFlat } from './icons/arrow-down-flat.svg'
import { ReactComponent as ArrowLeft } from './icons/arrow-left.svg'
import { ReactComponent as ArrowRight } from './icons/arrow-right.svg'
import { ReactComponent as ArrowRightCircle } from './icons/arrow-right-circle.svg'
import { ReactComponent as Chevron } from './icons/chevron.svg'
import { ReactComponent as Close } from './icons/close.svg'
import { ReactComponent as Discord } from './icons/discord.svg'
import { ReactComponent as Github } from './icons/github.svg'
import { ReactComponent as Telegram } from './icons/telegram.svg'
import { ReactComponent as Twitter } from './icons/twitter.svg'
import { ReactComponent as Volume1 } from './icons/volume-1.svg'
import { ReactComponent as VolumeX } from './icons/volume-x.svg'
import { ReactComponent as Controls } from './icons/controls.svg'
import { ReactComponent as InnovateAndBuild } from './icons/innovate-and-build.svg'
import { ReactComponent as CreateAndShare } from './icons/create-and-share.svg'
import { ReactComponent as EmpowerAndSupport } from './icons/empower-and-support.svg'
import { ReactComponent as Artist } from './icons/artist.svg'
import { ReactComponent as Publisher } from './icons/publisher.svg'
import { ReactComponent as CollectorAndCurator } from './icons/collector-and-curator.svg'
import { ReactComponent as ArtOwnership } from './icons/art-ownership.svg'
import { ReactComponent as Atomic } from './icons/atomic.svg'
import { ReactComponent as Censorship } from './icons/censorship.svg'
import { ReactComponent as Collection } from './icons/collection.svg'
import { ReactComponent as Fish } from './icons/fish.svg'
import { ReactComponent as Gallery } from './icons/gallery.svg'
import { ReactComponent as GlobalNetwork } from './icons/global-network.svg'
import { ReactComponent as Nft } from './icons/nft.svg'
import { ReactComponent as PiggyBank } from './icons/piggy-bank.svg'
import { ReactComponent as Reward } from './icons/reward.svg'
import { ReactComponent as Sales } from './icons/sales.svg'
import { ReactComponent as TakeDown } from './icons/take-down.svg'
import { ReactComponent as Target } from './icons/target.svg'
import { ReactComponent as Token } from './icons/token.svg'
import { ReactComponent as Adoption } from './icons/adoption.svg'
import { ReactComponent as BlockchainDeveloper } from './icons/blockchain-developer.svg'
import { ReactComponent as DeveloperTools } from './icons/developer-tools.svg'
import { ReactComponent as Entrepreneur } from './icons/entrepreneur.svg'
import { ReactComponent as FinnieWallet } from './icons/finnie-wallet.svg'
import { ReactComponent as Grants } from './icons/grants.svg'
import { ReactComponent as Inexperienced } from './icons/inexperienced.svg'
import { ReactComponent as KoiiDevs } from './icons/koii-devs.svg'
import { ReactComponent as NonBlockchainDeveloper } from './icons/non-blockchain-developer.svg'
import { ReactComponent as NoOpportunity } from './icons/no-opportunity.svg'
import { ReactComponent as Unqualified } from './icons/unqualified.svg'
import { ReactComponent as WebProgramming } from './icons/web-programming.svg'
import { ReactComponent as Astronaut } from './icons/astronaut.svg'
import { ReactComponent as Binoculars } from './icons/binoculars.svg'
import { ReactComponent as Empathy } from './icons/empathy.svg'
import { ReactComponent as GetPaid } from './icons/get-paid.svg'
import { ReactComponent as MailPhishing } from './icons/mail-phishing.svg'
import { ReactComponent as MoneyGrowth } from './icons/money-growth.svg'
import { ReactComponent as Record } from './icons/record.svg'
import { ReactComponent as RunNode } from './icons/run-node.svg'
import { ReactComponent as SadFace } from './icons/sad-face.svg'
import { ReactComponent as Sponsor } from './icons/sponsor.svg'
import { ReactComponent as SpreadWorld } from './icons/spread-world.svg'
import { ReactComponent as Stats } from './icons/stats.svg'

type SVG = React.FunctionComponent<React.SVGAttributes<SVGElement>>

type IconsMap = {
  [index: string]: SVG
}
const iconsMap: IconsMap = {
  logo: Logo,
  'arrow-up': ArrowUp,
  'arrow-up-flat': ArrowUpFlat,
  'arrow-down': ArrowDown,
  'arrow-down-flat': ArrowDownFlat,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-right-circle': ArrowRightCircle,
  chevron: Chevron,
  close: Close,
  controls: Controls,
  discord: Discord,
  github: Github,
  telegram: Telegram,
  twitter: Twitter,
  volume1: Volume1,
  volumeX: VolumeX,
  'innovate-and-build': InnovateAndBuild,
  'create-and-share': CreateAndShare,
  'empower-and-support': EmpowerAndSupport,
  artist: Artist,
  publisher: Publisher,
  'collector-and-curator': CollectorAndCurator,
  'art-ownership': ArtOwnership,
  atomic: Atomic,
  censorship: Censorship,
  collection: Collection,
  fish: Fish,
  gallery: Gallery,
  'global-network': GlobalNetwork,
  nft: Nft,
  'piggy-bank': PiggyBank,
  reward: Reward,
  sales: Sales,
  'take-down': TakeDown,
  target: Target,
  token: Token,
  adoption: Adoption,
  'blockchain-developer': BlockchainDeveloper,
  'developer-tools': DeveloperTools,
  entrepreneur: Entrepreneur,
  'finnie-wallet': FinnieWallet,
  grants: Grants,
  inexperienced: Inexperienced,
  'koii-devs': KoiiDevs,
  'non-blockchain-developer': NonBlockchainDeveloper,
  'no-opportunity': NoOpportunity,
  unqualified: Unqualified,
  'web-programming': WebProgramming,
  astronaut: Astronaut,
  binoculars: Binoculars,
  empathy: Empathy,
  'get-paid': GetPaid,
  'mail-phishing': MailPhishing,
  'money-growth': MoneyGrowth,
  record: Record,
  'run-node': RunNode,
  'sad-face': SadFace,
  sponsor: Sponsor,
  'spread-world': SpreadWorld,
  stats: Stats
}

export type IconName = keyof typeof iconsMap
export interface IconProps {
  color?: ColorName
  name: IconName
  className?: string
  title?: string
}

const defaultColor = 'lightGreen'
const StyledIcon = styled.span<{ color?: ColorName }>`
  display: inline-block;
  color: ${props => props.theme.colors[props.color || defaultColor]};
  width: auto;
  height: 100%;

  svg {
    width: auto;
    height: 100%;
  }
`

export function Icon (props: IconProps) {
  const ExactIcon = iconsMap[props.name]

  return (
    <StyledIcon color={props.color} className={props.className} role="img" aria-label={props.title}>
      {ExactIcon && <ExactIcon />}
    </StyledIcon>
  )
}

export default Icon
