import { Link } from 'gatsby'
import { Trans, useTranslation } from 'react-i18next'
import { breakpoints } from '../components/ui/breakpoints'
import styled from 'styled-components'

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin: auto;
  overflow: hidden;

  ${breakpoints.lg} {
    height: 437px;
    flex-direction: row;
  }
`

const Part = styled.div`
  ${breakpoints.lg} {
    flex: 1;
  }
`

const LeftPart = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;

  ${breakpoints.lg} {
    margin-top: 0;
    flex: 1;
  }
`

const Background = styled.div`
  background: ${props => props.theme.colors.black60};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  right: 0;
  transform: translateX(50%);

  ${breakpoints.lg} {
    transform: translateX(25%);
  }
`

const Title = styled.h1`
  font-weight: 300;
  margin-bottom: 16px;
  font-size: 28px;
  line-height: 1.25;
  color: ${props => props.theme.colors.white};
  margin: 0;

  ${breakpoints.lg} {
    font-size: 40px;
    line-height: 50px;
  }
`

const Description = styled.h2`
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.colors.gray1};
  margin: 28px 0;
  max-width: 490px;
`

const Links = styled.div`
  > * {
    margin-right: 27px;
  }

  > *:last-child {
    margin-right: 0;
  }
`

const BigTitle = styled.div`
  position: relative;
  z-index: 1;
  font-size: 120px;
  line-height: 200px;

  ${breakpoints.lg} {
    font-size: 148px;
    line-height: 240px;
  }
`

function NotFoundPage () {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Part>
        <Title>
          <Trans>pages.404.title</Trans>
        </Title>
        <Description>
          <Trans>pages.404.description</Trans>
        </Description>

        <Links>
          <Link to='/' className='underline'>
            {t('ui.common.goToBeginning')}
          </Link>
          <Link to='/explore' className='underline d-none d-xl-inline'>
            {t('ui.common.exploreKoii')}
          </Link>
        </Links>
      </Part>
      <LeftPart>
        <BigTitle>404</BigTitle>
        <Background />
      </LeftPart>
    </Wrapper>
  )
}

export default NotFoundPage
