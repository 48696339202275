import { createMedia } from '@artsy/fresnel'

const AppMedia = createMedia({
  breakpoints: {
    xs: 0,
    sm: 360,
    md: 768,
    lg: 1024,
    xl: 1200,
    xxl: 1400
  }
})

export const mediaStyles = AppMedia.createMediaStyle()
export const { Media, MediaContextProvider } = AppMedia
