// TypeScript and Gatsby can't handle @koii paths right now
// eslint-disable-next-line
import {
  IBlockConnection,
  ISpacedPageDescriptors,
  IPoint,
  ILayeredPages,
  ISpacedPageDescriptor,
  ICrumb,
  IBlockTypeName,
  IBlockDataSource
} from '../../../libs/interfaces/src'
export class Tree {
  private readonly pages: IBlockDataSource[] = []
  private readonly connections: IBlockConnection[] = []
  private readonly spacedPageDescriptors: ISpacedPageDescriptors = []

  constructor (pages: IBlockDataSource[], connections?: IBlockConnection[]) {
    this.pages = pages
    this.connections = connections
    const pagesInLayers = this.buildPagesInLayers()
    this.spacedPageDescriptors = this.buildSpacedPageDescriptors(pagesInLayers)
  }

  getSpacedPageDescriptors (): ISpacedPageDescriptors {
    return this.spacedPageDescriptors
  }

  pageTypeToLevelMapper (pageType: IBlockTypeName) {
    switch (pageType) {
      case 'intro':
        return 0
      case 'role':
        return 1
      case 'choice':
        return 2
      case 'feature':
        return 3
      case 'external':
        return 4
    }
  }

  getPositionFromIndex (
    horizontalPosition: number,
    rightBoundry: number,
    verticalPosition: number,
    bottomBoundry: number
  ): IPoint {
    // Y position
    // the element will be offset to the top
    // by the amount of levels divided by two
    const yMidPoint = bottomBoundry / 2
    const yRandomOffset = (Math.random() - 0.5) / 4 // range [-0.25, 0.25)
    const yPosition = verticalPosition - yMidPoint + yRandomOffset

    // X Position
    // the element will be offset to the left
    // by the amount of elements in line divided by two
    const xMidPoint = rightBoundry / 2
    const xRandomOffset = (Math.random() - 0.5) / 4 // range [-0.25, 0.25)
    const xPosition = horizontalPosition - xMidPoint + xRandomOffset

    return {
      y: yPosition,
      x: xPosition / 2
    }
  }

  buildPagesInLayers (): ILayeredPages {
    const layers = []

    for (const page of this.pages) {
      const level = this.pageTypeToLevelMapper(page.type)

      if (!layers[level]) {
        layers[level] = []
      }

      layers[level].push(page)
    }

    return layers
  }

  buildSpacedPageDescriptors (
    layeredPages: ILayeredPages
  ): ISpacedPageDescriptors {
    // Prepare output array
    const output: ISpacedPageDescriptors = []

    // Loop through layers
    for (let y = 0; y < layeredPages.length; y++) {
      const layer = layeredPages[y]

      // Loop through items in a layer
      for (let x = 0; x < layer.length; x++) {
        const page = layer[x]

        // Get position
        const position = this.getPositionFromIndex(
          x,
          layer.length - 1,
          y,
          layeredPages.length - 1
        )

        // Get payload
        const payload = {
          url: page.url,
          name: page.name
        }

        // Build single entry descriptor
        const spacedPageDescriptor: ISpacedPageDescriptor = {
          position,
          payload
        }

        // Add to a output
        output.push(spacedPageDescriptor)
      }
    }
    return output
  }

  public getConnection (name: string): IBlockConnection | undefined {
    return this.connections.find(connection => connection.from === name)
  }

  getPathFromNodeToRoot (name: string): ICrumb[] {
    // Remember level of given node - may not be needed
    const level = { index: 0 }
    // Remember parent of given node - needed for generating path
    const parent = { index: null }

    // Current level
    let i = 1

    // Current level elements
    let frontier = ['index']

    // Loop through current level
    while (frontier.length > 0) {
      // Prepare next level elements
      const next = []

      // For each element in the current level
      for (const u of frontier) {
        // Find children/connections
        const connection = this.getConnection(u)
        const children = connection ? connection.to : []

        // For each connection
        for (const v of children) {
          // If was not previously visited
          if (level[v] == null) {
            // Set level, parent, and add to next level
            level[v] = i
            parent[v] = u
            next.push(v)
          }
        }

        // Update current level to the next level
        frontier = next

        // Update level counter
        i++
      }
    }

    // Prepare place for a path
    const path = []

    // If name was fount
    if (parent[name]) {
      // Setup backtracking of path
      let currentNode = name
      while (currentNode) {
        // Get page data for given path element
        const pageData = this.getPageData(currentNode)
        const parentNode = parent[currentNode]
        if (!pageData || !parentNode) break

        // Update path with breadcrumb data
        path.push({
          name: currentNode,
          url: pageData.url
        })

        // Update currently processed node as a parent
        currentNode = parentNode
      }
    }

    return path
  }

  getBreadcrumbForName (name: string): ICrumb[] {
    return this.getPathFromNodeToRoot(name)
  }

  getPageData (name: string): IBlockDataSource | undefined {
    return this.pages.find(page => page.name === name)
  }
}
