import React from 'react'
import { useSetup } from './setup'
import { useToggleSound } from './toggleSound'
import { usePageVisibility } from './pageVisibility'
import { useBlockHovered } from './blockHovered'
import { use3dBlockMouse } from './3dBlockMouse'
import { usePageOpen } from './pageOpen'
import { useStageSetup } from './stageSetup'

function UseCases () {
  useSetup()
  useStageSetup()
  usePageOpen()
  useToggleSound()
  usePageVisibility()
  useBlockHovered()
  use3dBlockMouse()

  return null
}

export default React.memo(UseCases)
