import { SoundService } from '@koii/sound'
import { Stage } from '@koii/stage'
import Container from 'typedi'
import { useEvent } from '../events'
import { useStore } from '../store'

export function useBlockHovered () {
  const [{ soundEnabled }] = useStore()
  Container.set('soundEnabled', soundEnabled)

  useEvent('mouseEnteredBlock', (name: string) => {
    try {
      const soundEnabled = Container.get<boolean>('soundEnabled')
      const stage = Container.get<Stage>('stage')
      const soundService = Container.get<SoundService>('soundService')
      const blockHoveredSound = soundService.getSound('blockHovered')

      stage.setActiveBlock(name)

      if (soundEnabled && blockHoveredSound) {
        blockHoveredSound.play()
      }
    } catch (e) {
      console.log('mouseEnteredBlock error', e)
    }
  })

  useEvent('mouseLeftBlock', () => {
    try {
      const stage = Container.get<Stage>('stage')
      stage.setActiveBlock('')
    } catch (e) {
      console.log('mouseLeftBlock error', e)
    }
  })
}
