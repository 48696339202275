import Icon, { IconName } from '../icon/Icon'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

const Wrapper = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
  background-color: #0f1924cc;
  box-shadow: 0 15px 19px #9be7c40c;
  max-width: 560px;
`

const Container = styled.aside`
  padding: 24px;
  position: relative;
`

const Header = styled.div`
  margin-bottom: 30px;
`
const HeaderIconWrapper = styled.div`
  display: inline-block;
  margin-right: 24px;
  vertical-align: middle;
`

const Title = styled.h3`
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.theme.colors.lightGreen};
`

const ActionsWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

const Content = styled.div`
  font-size: 16px;
  line-height: 1.25;
  color: ${props => props.theme.colors.white};

  > p:not(:last-child) {
    margin: 0 0 23px 0;
  }
`

const MinifyButton = styled.button`
  transition: background-color 250ms ease-in-out;
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  position: relative;
  pointer-events: all;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:after {
    position: absolute;
    content: '';
    width: 20px;
    height: 1px;
    background-color: #ffffffb2;
    top: 20px;
    left: 6px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${props => props.theme.colors.gradientBg};
  }
`

const OpenButton = styled.button`
  transition: background-color 250ms ease-in-out;
  pointer-events: all;
  cursor: pointer;
  margin: 0;
  border: none;
  background-color: transparent;
  padding: 15px 30px 15px 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: ${props => props.theme.colors.gradientBg};
  }
`

export function BlockLegend () {
  const [isVisible, setIsVisible] = useState<boolean>(true)
  const { t } = useTranslation()

  return (
    <Wrapper>
      {isVisible ? (
        <Container role='complementary'>
          <ActionsWrapper>
            <MinifyButton onClick={() => setIsVisible(false)} />
          </ActionsWrapper>
          <Header>
            <HeaderIconWrapper>
              <Icon name='controls' />
            </HeaderIconWrapper>
            <Title>{t('ui.floatingLegend.title')}</Title>
          </Header>
          <Content>
            <p>{t('ui.floatingLegend.zoomHelp')}</p>
            <p>{t('ui.floatingLegend.orientationHelp')}</p>
            <p>{t('ui.floatingLegend.positionHelp')}</p>
            <p>{t('ui.floatingLegend.exploreHelp')}</p>
          </Content>
        </Container>
      ) : (
        <OpenButton onClick={() => setIsVisible(true)}>
          <HeaderIconWrapper>
            <Icon name='controls' />
          </HeaderIconWrapper>
          <Title>{t('ui.floatingLegend.title')}</Title>
        </OpenButton>
      )}
    </Wrapper>
  )
}

export default BlockLegend
