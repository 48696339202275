import React, { useEffect } from 'react'
import { eventBus } from '../app/events'
import styled from 'styled-components'
import Loadable from '@loadable/component'
import Theme from '../components/ui/theme/Theme'
import i18n from '../i18n'
import { breakpoints } from '../components/ui/breakpoints'
import { ICrumb } from '@koii/interfaces'
import { Provider as ReakitProvider } from 'reakit'
import { I18nextProvider } from 'react-i18next'
import { PageProps } from 'gatsby'
import { Media, MediaContextProvider } from '../components/Media'
import Header from '../components/ui/header/Header'
import Footer from '../components/ui/footer/Footer'
import { useBreadcrumbs } from '../app/useBreadcrumbs'

const UseCases = Loadable(() => import('../app/useCases/UseCases'))
const SoundDependencies = Loadable(() => import('../app/SoundDependencies'))
const Stage = Loadable(() => import('../components/Stage'))

const Content = styled.div`
  z-index: 10;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.dimmedBackground};
  transition: background-color 0.3s ease-in-out;

  &.non-clickable {
    background-color: transparent;
    pointer-events: none;
  }
`

const Background = styled.div`
  position: fixed;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;

  background-image: url('bg.png');
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;

  ${breakpoints.lg} {
    background-image: none;
  }
`
const Wrapper = styled.main`
  height: 100%;
  margin: auto;
  flex: 1 1 auto;
  display: flex;
  padding: 20px 20px 70px;
  width: 100%;
  box-sizing: border-box;

  > * {
    flex: 1 1;
  }

  ${breakpoints.sm} {
    margin: 0;
  }

  ${breakpoints.lg} {
    margin: 0 auto;
    width: 90%;
    max-width: 1440px;
    padding: 0;
  }
`

function handleVisibilityChange () {
  eventBus.emit('pageVisibilityChanged')
}

export interface PageData {
  name: string
}
export interface PageContext {
  crumbs: ICrumb[]
  currentLevel: number
  connections: string[]
}

export function IndexLayout (props: PageProps<PageData, PageContext>) {
  const isExplorationMode = props.location.pathname.includes('explore')
  const breadcrumbs = useBreadcrumbs(props)

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange, false)

    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [])

  const showGoToBeginning = !['/', '/explore'].includes(props.uri)

  return (
    <Theme>
      <UseCases />
      <ReakitProvider>
        <I18nextProvider i18n={i18n}>
          <MediaContextProvider>
            <Content className={isExplorationMode ? 'non-clickable' : ''}>
              <Header
                crumbs={breadcrumbs}
                currentLevel={props.pageContext.currentLevel}
                showExploreLink={props.uri !== '/explore'}
                showExitLink={props.uri === '/explore'}
              />
              <Wrapper>{props.children}</Wrapper>
              <Footer showGoToBeginning={showGoToBeginning} />
            </Content>
            <Background>
              <Media greaterThanOrEqual='xl'>
                <SoundDependencies />
                <Stage />
              </Media>
            </Background>
          </MediaContextProvider>
        </I18nextProvider>
      </ReakitProvider>
    </Theme>
  )
}

export default IndexLayout
