import { IBlockDataSource } from '@koii/interfaces'
import { eventBus } from './../events'
import { Stage } from '@koii/stage'
import Container from 'typedi'
import { useEvent } from '../events'

import { Tree } from '../../../content/tree'

export function useStageSetup () {
  useEvent('stageComponentLoaded', (pages: IBlockDataSource[]) => {
    const isDebugMode = window.location.hash === '#debug'
    const canvas = document.querySelector('#stage') as HTMLCanvasElement
    const tree = new Tree(pages)
    const stage = new Stage(
      canvas,
      tree.getSpacedPageDescriptors(),
      isDebugMode
    )

    stage.onBlockClick((name: string) => {
      eventBus.emit('mouseClicked3dBlock', name)
    })
    stage.onBlockMouseEnter(() => {
      eventBus.emit('mouseEntered3dBlock')
    })
    stage.onBlockMouseLeft(() => {
      eventBus.emit('mouseLeft3dBlock')
    })

    Container.set<Stage>('stage', stage)
    Container.set<Stage>('canvas', canvas)
    Container.set<Stage>('tree', tree)
  })
}
