import React from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import Block from './ui/block/Block'
import { breakpoints } from './ui/breakpoints'
import Collapse from './ui/collapse/Collapse'
import { eventBus } from '../app/events'
import BlockOutputsList from './ui/blockOutputsList/BlockOutputsList'
import CollapseOutputs from './ui/collapseOutputs/CollapseOutputs'
import { Media } from './Media'
import { YamlPage } from '@koii/interfaces'
import Tooltip from './ui/tooltip/Tooltip'

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;

  ${breakpoints.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Content = styled.section`
  display: block;
  margin-bottom: 60px;
  max-width: 568px;

  ${breakpoints.sm} {
    font-size: 40px;
    line-height: 60px;
  }

  ${breakpoints.lg} {
    display: flex;
    justify-content: center;
    max-width: none;
    flex-direction: column;
    padding-right: 50px;
    margin-bottom: 0;
    width: calc(50% - 100px);
  }
`

const Title = styled.h1`
  font-weight: 300;
  color: ${props => props.theme.colors.white};
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 28px;
  line-height: 1.25;

  ${breakpoints.sm} {
    font-size: 40px;
    line-height: 60px;
  }
`

const Description = styled.h2`
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
  color: ${props => props.theme.colors.gray1};
  margin-top: 0;
  margin-bottom: 45px;
  text-shadow: 0px 2px 40px ${props => props.theme.colors.black};

  ${breakpoints.sm} {
    margin-bottom: 36px;
  }

  ${breakpoints.lg} {
    margin-bottom: 55px;
  }
`

const Button = styled.div`
  font-weight: 300;
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;

  font-size: 22px;
  line-height: 24px;

  ${breakpoints.lg} {
    font-size: 18px;
    line-height: 23px;
  }
`

const BlockOutputs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 640px;
`

function IntroPage (props: YamlPage) {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Content>
        <Title>{t(`content.index.title`)}</Title>
        <Description>
          <Trans
            components={{
              tooltip: <Tooltip />
            }}
          >
            {`content.index.description`}
          </Trans>
        </Description>
        <Button>{t(`pages.index.ctaButton`)}</Button>
      </Content>

      <Media lessThan='xl'>
        <CollapseOutputs>
          {props.outputs.map(output => (
            <Collapse
              key={output.url}
              title={t(t(`content.${output.name}.title`))}
              url={output.url}
              description={t(`content.${output.name}.description`)}
              iconName={output.icon}
              ctaButton={t(`pages.index.collapse`)}
            />
          ))}
        </CollapseOutputs>
      </Media>

      <BlockOutputs>
        <Media at='xl'>
          <BlockOutputsList direction='column' gap={15}>
            {props.outputs.map(output => (
              <Block
                key={output.url}
                shrinked
                title={t(t(`content.${output.name}.title`))}
                url={output.url}
                description={t(`content.${output.name}.description`)}
                iconName={output.icon}
                onMouseEnter={() =>
                  eventBus.emit('mouseEnteredBlock', output.name)
                }
                onMouseLeave={() =>
                  eventBus.emit('mouseLeftBlock', output.name)
                }
              />
            ))}
          </BlockOutputsList>
        </Media>
        <Media greaterThanOrEqual='xxl'>
          <BlockOutputsList direction='column' gap={37}>
            {props.outputs.map(output => (
              <Block
                key={output.url}
                title={t(t(`content.${output.name}.title`))}
                url={output.url}
                description={t(`content.${output.name}.description`)}
                iconName={output.icon}
                onMouseEnter={() =>
                  eventBus.emit('mouseEnteredBlock', output.name)
                }
                onMouseLeave={() =>
                  eventBus.emit('mouseLeftBlock', output.name)
                }
              />
            ))}
          </BlockOutputsList>
        </Media>
      </BlockOutputs>
    </Wrapper>
  )
}

export default IntroPage
