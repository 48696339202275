import { SoundService } from '@koii/sound'
import Container from 'typedi'
import { useEvent } from '../events'
import { useStore } from '../store'

export function usePageVisibility () {
  const [{ soundEnabled }] = useStore()
  Container.set('soundEnabled', soundEnabled)

  useEvent('pageVisibilityChanged', () => {
    try {
      const soundEnabled = Container.get<boolean>('soundEnabled')
      const soundService = Container.get<SoundService>('soundService')
      const backgroundLoop = soundService.getSound('backgroundLoop')

      if (soundEnabled) {
        if (document.visibilityState === 'hidden') {
          backgroundLoop.stop()
        } else {
          backgroundLoop.play()
        }
      }
    } catch (e) {
      console.log('pageVisibilityChanged error', e)
    }
  })
}
