import { SoundService } from '@koii/sound'
import Container from 'typedi'
import { useEvent } from '../events'
import { useStore } from '../store'
import isMobile from 'is-mobile'

export function useSetup () {
  const [{ soundEnabled }] = useStore()
  Container.set('soundEnabled', soundEnabled)

  useEvent('appOpened', () => {
    const soundEnabled = Container.get<boolean>('soundEnabled')
    const soundService = Container.get<SoundService>('soundService')
    const backgroundLoop = soundService.getSound('backgroundLoop')
    const isDesktop = !isMobile({
      tablet: true,
      featureDetect: true
    })

    if (soundEnabled && isDesktop) {
      backgroundLoop.play()
    }
  })
}
