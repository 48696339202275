import Icon, { IconName } from '../icon/Icon'
import styled from 'styled-components'

export interface BlockFeatureProps {
  title: string
  url: string
  description: string
  iconName: IconName
  ctaButton: string
  onMouseEnter: () => void
  onMouseLeave: () => void
}

const Background = styled.div`
  background: ${props => props.theme.colors.black60};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  right: 0;
  transition: width 0.8s cubic-bezier(0.83, 0, 0.17, 1);
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
`

const Title = styled.h3`
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;
  margin: 0;
  color: ${props => props.theme.colors.white};

  a {
    font-size: inherit;
  }

  a:hover {
    color: inherit;
  }
`

const Description = styled.h4`
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  margin: 32px 0 48px;
  color: ${props => props.theme.colors.gray1};
`

const Text = styled.div`
  position: relative;
  z-index: 1;
  width: 60%;
  padding-left: 40px;
`

const LeftPart = styled.div``

const IconPart = styled.div`
  background: ${props => props.theme.colors.lightGreen};
  width: 64px;
  height: 64px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.theme.colors.lightGreen};
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
`

const Button = styled.div`
  width: 48px;
  margin-left: 24px;
`

const Wrapper = styled.div`
  position: relative;

  padding: 30px;

  svg {
    transition: all 1s;
  }

  &:hover ${ButtonWrapper} {
    transform: translateX(10px);

    svg {
      color: ${props => props.theme.colors.darkBlue};
      fill: ${props => props.theme.colors.lightGreen};
    }
  }

  a {
    display: inline-block;
    font-size: 18px;
    line-height: 23px;
  }
`

export function BlockFeature (props: BlockFeatureProps) {
  return (
    <Wrapper
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <a href={props.url}>
        <Content>
          <LeftPart>
            <IconPart>
              <Icon name={props.iconName} color='darkBlue' />
            </IconPart>
          </LeftPart>
          <Text>
            <Title>{props.title}</Title>
            <Description>{props.description}</Description>
            <ButtonWrapper>
              {props.ctaButton}
              <Button>
                <Icon name='arrow-right-circle' />
              </Button>
            </ButtonWrapper>
          </Text>
        </Content>
      </a>
      <Background />
    </Wrapper>
  )
}

export default BlockFeature
