import styled from 'styled-components'

export interface BlockOutputsListProps {
  direction?: 'row' | 'column'
  gap: number
}

export const Wrapper = styled.nav<BlockOutputsListProps>`
  display: flex;

  &.row {
    flex-direction: row;

    > * {
      margin-right: ${props => props.gap}px;
      flex-shrink: 0;
    }

    > *:last-child {
      margin-right: 0;
    }
  }

  &.column {
    flex-direction: column;

    > * {
      margin-bottom: ${props => props.gap}px;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
`

function BlockOutputsList (
  props: React.PropsWithChildren<Required<BlockOutputsListProps>>
) {
  return (
    <Wrapper className={props.direction} gap={props.gap}>
      {props.children}
    </Wrapper>
  )
}
export default BlockOutputsList
