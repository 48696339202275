import Icon, { IconName } from '../icon/Icon'
import styled from 'styled-components'
import { Link } from 'gatsby'

export interface BlockRoleProps {
  title: string
  url: string
  description: string
  iconName: IconName
  onMouseEnter: () => void
  onMouseLeave: () => void
}

const Background = styled.div`
  background: ${props => props.theme.colors.black60};
  position: absolute;
  width: 74%;
  height: 90%;
  z-index: 0;
  bottom: 0;
  right: 0;
  transition: width 0.8s cubic-bezier(0.83, 0, 0.17, 1);
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
const TitleWrapper = styled.div`
  transition: all 0.5s ease-in-out;
`

const Title = styled.h3`
  font-size: 28px;
  line-height: 35px;
  font-weight: 300;
  margin: 0;

  a {
    font-size: inherit;
  }

  a:hover {
    color: inherit;
  }
`

const TitleLine = styled.div`
  transform: translateY(8px);
  height: 2px;
  background: ${props => props.theme.colors.white};
  transition: all 0.3s cubic-bezier(0.55, 0, 1, 0.45);
  transition-delay: 0.3s;
  opacity: 0;
  width: 0%;
`

const Description = styled.h4`
  font-size: 16px;
  line-height: 150%;
  font-weight: 300;
  color: ${props => props.theme.colors.gray1};
  margin: 32px 46px 0 0;
  transition: all 0.3s ease-in-out;
`
const Top = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

const IconPart = styled.div`
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  padding: 20px;
  margin-right: 30px;
  margin-bottom: 13px;
  background: ${props => props.theme.colors.lightGreen};
`

const Arrow = styled.div`
  opacity: 0;
  height: 40px;
  transition: opacity 0.3s ease-in-out;
  margin-top: 20px;
`

const Wrapper = styled.div`
  position: relative;
  width: 418px;
  height: auto;

  &:hover ${Background} {
    width: 84%;
  }

  &:hover ${TitleWrapper} {
    color: ${props => props.theme.colors.white};
  }

  &:hover ${TitleLine} {
    opacity: 1;
    width: 100%;
  }

  &:hover ${TitleWrapper} {
    transform: translateY(0);
  }

  &:hover ${Arrow} {
    opacity: 1;
  }
`

export function BlockRole (props: BlockRoleProps) {
  return (
    <Wrapper
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <Link to={props.url}>
        <Content>
          <div>
            <Top>
              <TitleWrapper>
                <Title>{props.title}</Title>
                <TitleLine />
              </TitleWrapper>
              <IconPart>
                <Icon name={props.iconName} color='darkBlue' />
              </IconPart>
            </Top>
            <Description>{props.description}</Description>
          </div>
          <Arrow>
            <Icon name='arrow-right' color='white' />
          </Arrow>
        </Content>
      </Link>
      <Background />
    </Wrapper>
  )
}

export default BlockRole
