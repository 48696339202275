import { Tree } from '../../../content/tree'
import { SoundService } from '@koii/sound'
import Container from 'typedi'
import { useEvent } from '../events'
import { useStore } from '../store'

export function use3dBlockMouse () {
  const [{ soundEnabled }, { setFloatingBlock }] = useStore()
  Container.set('soundEnabled', soundEnabled)

  useEvent('mouseClicked3dBlock', (name: string) => {
    try {
      const soundEnabled = Container.get<boolean>('soundEnabled')
      const soundService = Container.get<SoundService>('soundService')
      const blockHoveredSound = soundService.getSound('blockHovered')
      const tree = Container.get<Tree>('tree')
      const pageData = tree.getPageData(name)

      if (soundEnabled) {
        blockHoveredSound.play()
      }

      setFloatingBlock(pageData)
    } catch (e) {
      console.log('mouseClicked3dBlock error', e)
    }
  })

  useEvent('mouseEntered3dBlock', () => {
    try {
      const soundEnabled = Container.get<boolean>('soundEnabled')
      const soundService = Container.get<SoundService>('soundService')
      const blockHoveredSound = soundService.getSound('blockHovered')
      const canvas = Container.get<HTMLCanvasElement>('canvas')

      if (soundEnabled) {
        blockHoveredSound.play()
      }

      canvas.style.cursor = 'pointer'
    } catch (e) {
      console.log('mouseEntered3dBlock error', e)
    }
  })

  useEvent('mouseLeft3dBlock', () => {
    try {
      const canvas = Container.get<HTMLCanvasElement>('canvas')

      canvas.style.cursor = 'grab'
    } catch (e) {
      console.log('mouseLeft3dBlock error', e)
    }
  })
}
