import Icon, { IconName } from '../icon/Icon'
import styled from 'styled-components'
import { Link } from 'gatsby'
import cx from 'classnames'

export interface BlockProps {
  title: string
  url: string
  description: string
  iconName: IconName
  onMouseEnter: () => void
  onMouseLeave: () => void
  shrinked?: boolean
}

const Background = styled.div`
  background: ${props => props.theme.colors.black60};
  position: absolute;
  width: 80%;
  height: 100%;
  z-index: 0;
  top: 0;
  right: 0;
  transition: width 0.8s cubic-bezier(0.83, 0, 0.17, 1);
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
`
const TitleWrapper = styled.div`
  transform: translateY(150%);
  transition: all 0.5s ease-in-out;
  margin-top: 20px;
  display: inline-block;
`

const Title = styled.h3`
  font-size: 28px;
  line-height: 35px;
  font-weight: 300;
  margin: 0;

  &.shrinked {
    font-size: 24px;
    line-height: 26px;
  }

  a {
    font-size: inherit;
  }

  a:hover {
    color: inherit;
  }
`

const TitleLine = styled.div`
  margin-top: 8px;
  height: 2px;
  background: ${props => props.theme.colors.white};
  transition: all 0.3s cubic-bezier(0.55, 0, 1, 0.45);
  transition-delay: 0.3s;
  opacity: 0;
  width: 0%;
`

const Description = styled.h4`
  font-size: 16px;
  line-height: 150%;
  font-weight: 300;
  color: ${props => props.theme.colors.gray1};
  opacity: 0;
  transform: translateX(40px);
  transition: all 0.3s ease-in-out;

  &.shrinked {
    font-size: 15px;
    line-height: 140%;
    margin: 14px 0;
  }
`
const Text = styled.div`
  position: relative;
  z-index: 1;
  width: 60%;
`

const RightPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
`

const IconPart = styled.div`
  background: ${props => props.theme.colors.lightGreen};
  width: 80px;
  height: 80px;
  padding: 16px;
  box-sizing: border-box;
`

const Arrow = styled.div`
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`

const Wrapper = styled.div`
  position: relative;

  &:hover ${Background} {
    width: 85%;
  }

  &:hover ${Description} {
    transform: translateX(0);
    opacity: 1;
  }

  &:hover ${TitleWrapper} {
    color: ${props => props.theme.colors.white};
  }

  &:hover ${TitleLine} {
    opacity: 1;
    width: 100%;
  }

  &:hover ${TitleWrapper} {
    transform: translateY(0);
  }

  &:hover ${Arrow} {
    opacity: 1;
  }
`

export function Block (props: BlockProps) {
  const commonClassName = cx({
    shrinked: props.shrinked
  })

  return (
    <Wrapper
      className={commonClassName}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <Link to={props.url}>
        <Content>
          <Text>
            <TitleWrapper>
              <Title className={commonClassName}>{props.title}</Title>
              <TitleLine />
            </TitleWrapper>
            <Description className={commonClassName}>
              {props.description}
            </Description>
            <Arrow>
              <Icon name='arrow-right' color='white' />
            </Arrow>
          </Text>
          <RightPart>
            <IconPart>
              <Icon name={props.iconName} color='darkBlue' />
            </IconPart>
          </RightPart>
        </Content>
      </Link>
      <Background />
    </Wrapper>
  )
}

export default Block
