import { Link } from 'gatsby'
import { useState } from 'react'
import styled from 'styled-components'
import { breakpoints } from '../breakpoints'
import Icon, { IconName } from '../icon/Icon'

export interface CollapseProps {
  title: string
  description: string
  ctaButton: string
  url: string
  iconName: IconName
}

const Background = styled.div`
  background: ${props => props.theme.colors.gradientBg80};
  position: absolute;
  width: 70%;
  height: 100%;
  z-index: 0;
  top: 0;
  right: 0;

  ${breakpoints.sm} {
    width: 80%;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
`

const Wrapper = styled.div`
  position: relative;
  padding: 22px 20px 22px 0;
`

const ContentCollapse = styled.div`
  display: none;

  &.show {
    display: block;
  }
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Title = styled.h1`
  font-size: 18px;
  line-height: 23px;
  font-weight: 300;
`

const IconPart = styled.div`
  background: ${props => props.theme.colors.lightGreen};
  width: 48px;
  height: 48px;
  padding: 8px;
  box-sizing: border-box;
  margin-right: 20px;
`
const Arrow = styled.div`
  margin-left: auto;
`

const Description = styled.h4`
  font-size: 16px;
  line-height: 150%;
  font-weight: 300;
  color: ${props => props.theme.colors.gray1};
`

const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  line-height: 23px;
  font-weight: 300;
  color: ${props => props.theme.colors.lightGreen};
`

const ButtonIcon = styled.div`
  margin-left: 24px;
`

export function Collapse (props: React.PropsWithChildren<CollapseProps>) {
  const [show, setShow] = useState(false)

  function handleClick () {
    setShow(!show)
  }

  return (
    <Wrapper>
      <Content>
        <TitleWrapper onClick={handleClick}>
          <IconPart>
            <Icon name={props.iconName} color='darkBlue' />
          </IconPart>
          <Title>{props.title}</Title>
          <Arrow>
            {show ? (
              <Icon name='arrow-up-flat' color='white' />
            ) : (
              <Icon name='arrow-down-flat' color='white' />
            )}
          </Arrow>
        </TitleWrapper>
        <ContentCollapse className={show && 'show'}>
          <Description>{props.description}</Description>
          <Link to={props.url}>
            <Button>
              {props.ctaButton}
              <ButtonIcon>
                <Icon name='arrow-right-circle' />
              </ButtonIcon>
            </Button>
          </Link>
        </ContentCollapse>
      </Content>
      <Background />
    </Wrapper>
  )
}

export default Collapse
