import { IBlockDataSource } from '@koii/interfaces'
import { createStore, createHook, defaults, Action } from 'react-sweet-state'
import { produce } from 'immer'

defaults.mutator = (currentState, producer) => produce(currentState, producer)

type State = {
  soundEnabled: boolean
  floatingBlockData?: IBlockDataSource
}
type Actions = typeof actions

const initialState: State = {
  soundEnabled: true,
  floatingBlockData: null
}

const actions = {
  setSoundEnabled: (value: boolean): Action<State> => ({ setState }) => {
    setState(draft => {
      draft.soundEnabled = value
    })
  },
  setFloatingBlock: (data: IBlockDataSource): Action<State> => ({
    setState
  }) => {
    setState(draft => {
      draft.floatingBlockData = data
    })
  }
}

const Store = createStore<State, Actions>({
  initialState,
  actions
})

export const useStore = createHook(Store)
