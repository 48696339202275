import React from 'react'
import SEO from '../components/seo'
import IntroPage from '../components/IntroPage'
import ChoicePage from '../components/ChoicePage'
import RolePage from '../components/RolePage'
import FeaturePage from '../components/FeaturePage'
import { YamlPage } from '@koii/interfaces'

interface IContext {
  pageContext: YamlPage
}

class PageContentFactory {
  static create (page: YamlPage): React.ReactNode {
    switch (page.type) {
      case 'intro':
        return <IntroPage {...page}></IntroPage>

      case 'role':
        return <RolePage {...page}></RolePage>

      case 'choice':
        return <ChoicePage {...page}></ChoicePage>

      case 'feature':
        return <FeaturePage {...page}></FeaturePage>
    }
  }
}

export function DefaultTemplate (context: IContext) {
  const { pageContext } = context

  return (
    <>
      <SEO
        title={`content.${pageContext.name}.title`}
        description={`content.${pageContext.name}.description`}
      />
      {PageContentFactory.create(pageContext)}
    </>
  )
}

export default DefaultTemplate
