import React from 'react'
import {
  DefaultTheme,
  ThemeProvider,
  createGlobalStyle
} from 'styled-components'
import 'normalize.css'
import './fonts.css'
import './utils.css'

export interface ThemeProps {
  children: React.ReactNode
}

export const defaultTheme: DefaultTheme = {
  colors: {
    darkGreen: '#237B75',
    lightGreen: '#9BE7C4',
    darkBlue: '#171753',
    lightBlue: '#ECFFFE',
    white: '#FFFFFF',
    black: '#000000',
    black60: '#00000060',
    gradientBg: '#0F1924',
    gradientBg80: '#0F192480',
    gray1: '#A7ACB1',
    dimmedBackground: '#00000070'
  }
}

export type ColorName = keyof DefaultTheme['colors']
export const colors = Object.keys(defaultTheme.colors)

const Global = createGlobalStyle`
html, body {
  font-family: Sora, 'Lucida Sans', sans-serif;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.gradientBg};
}

a:link {
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  transition: all .2s ease-in;
  font-size: 14px;
  line-height: 18px;
}

.underline {
  border-bottom: 1px solid ${props => props.theme.colors.white};
  padding-bottom: 4px;
}

a:visited {
  color: ${props => props.theme.colors.white};
}

a:hover {
  color: ${props => props.theme.colors.lightGreen};
  border-color: ${props => props.theme.colors.lightGreen};
}

a:active {
  color: ${props => props.theme.colors.darkGreen};
}

// Dat.GUI Debug controls, we override their styles on purpose
.dg.ac {
  z-index: 10 !important;
}

`

export function Theme (props: ThemeProps) {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Global />
      {props.children}
    </ThemeProvider>
  )
}

export default Theme
