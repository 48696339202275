import styled from 'styled-components'
import {
  Tooltip as ReakitTooltip,
  TooltipReference,
  useTooltipState
} from 'reakit/Tooltip'

const Wrapper = styled.span`
  border-bottom: 1px solid;
  cursor: help;
`

const Inner = styled.div`
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  opacity: 0;
  transform-origin: top center;
  transform: translate3d(0, -20px, 0);
  [data-enter] & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const Content = styled.div`
  padding: 20px;
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.darkBlue};
`

const Title = styled.div`
  margin-bottom: 12px;
  color: ${props => props.theme.colors.darkGreen};
`

const Arrow = styled.div`
  width: 16px;
  height: 16px;
  transform: rotateZ(45deg);
  margin: -8px auto;
  background: ${props => props.theme.colors.white};
`

interface ITooltipProps {
  name?: string
}

function Tooltip (props: React.PropsWithChildren<ITooltipProps>) {
  const tooltip = useTooltipState({ animated: 250 })

  return (
    <Wrapper>
      <TooltipReference {...tooltip} as='span'>
        {props.name}
      </TooltipReference>
      <ReakitTooltip
        {...tooltip}
        unstable_popoverStyles={{
          ...tooltip.unstable_popoverStyles,
          zIndex: 100
        }}
      >
        <Inner>
          <Content>
            <Title>{props.name}</Title>
            {props.children}
          </Content>
          <Arrow />
        </Inner>
      </ReakitTooltip>
    </Wrapper>
  )
}

export default Tooltip
