import { useEffect, useState } from 'react'
import { ICrumb } from '@koii/interfaces'
import { PageContext } from 'gatsby/internal'
import { PageProps } from 'gatsby'
import { PageData } from '../layouts'

interface LastPageData {
  name: string
  level: number
  connections: string[]
}

export const useBreadcrumbs = (pageProps: PageProps<PageData, PageContext>) => {
  const [breadcrumbs, setBreadcrumbs] = useState<ICrumb[]>([])
  const [lastPageData, setLastPageData] = useState<LastPageData | null>()

  useEffect(() => {
    const newPageData: LastPageData = {
      // eslint-disable-next-line
      // @ts-ignore
      name: pageProps.pageContext.name,
      level: pageProps.pageContext.currentLevel,
      connections: pageProps.pageContext.connections
    }
    if (
      !lastPageData || // No last page data at all
      newPageData.level < 2 || // User is on the index or first layer
      Math.abs(newPageData.level - lastPageData.level) !== 1 // User jumped between more than one page
    ) {
      // Cannot determinate breadcrumbs
      setBreadcrumbs(pageProps.pageContext.crumbs ?? [])

      // current page is child of previous page
    } else if (lastPageData.connections.includes(newPageData.name)) {
      // Add current page to the beginning of breadcrumbs
      setBreadcrumbs(currentBreadcrumbs => [
        {
          name: newPageData.name,
          url: pageProps.path
        },
        ...currentBreadcrumbs
      ])

      // previous page is a child of current page
    } else if (newPageData.connections.includes(lastPageData.name)) {
      // remove first entry from breadcrumbs
      setBreadcrumbs(currentBreadcrumbs => currentBreadcrumbs.slice(1))
    } else {
      // Fallback to static breadcrumbs since prev <-> next pages are not connected
      setBreadcrumbs(pageProps.pageContext.crumbs ?? [])
    }

    setLastPageData(newPageData)
  }, [pageProps.location.pathname])

  return breadcrumbs
}
