import gsap from 'gsap/all'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Icon from './ui/icon/Icon'
import throttle from 'lodash/throttle'
export interface CarouselProps {
  mode?: 'vertical' | 'horizontal'
}

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  &.vertical {
    flex-direction: row;
    align-items: flex-end;

    > * {
      margin-right: 50px;
    }

    > *:last-child {
      margin-right: 0;
    }
  }

  &.horizontal {
    flex-direction: column;
    align-items: flex-end;

    > * {
      margin-bottom: 60px;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
`

export const Overflow = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Content = styled.div`
  &.vertical {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .overflows & {
      display: block;
    }
  }
`

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 14px;
  box-sizing: border-box;
  background: ${props => props.theme.colors.gradientBg80};

  &[hidden] {
    visibility: hidden;
  }

  &.vertical {
    width: 54px;
    height: 140px;
    flex-direction: column;
  }

  &.horizontal {
    width: 140px;
    height: 54px;
    flex-direction: row;
  }
`

export const ControlsSeparator = styled.div`
  background: ${props => props.theme.colors.lightGreen};

  &.vertical {
    width: 100%;
    height: 1px;
  }

  &.horizontal {
    width: 1px;
    height: 100%;
  }
`
export const IconWrapper = styled.button`
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`

const scrollLeftMargin = 5
const scrollStep = 200
const scrollAnimation = {
  ease: 'expo.out',
  duration: 0.5
}

export function Carousel ({
  mode,
  children
}: React.PropsWithChildren<CarouselProps>) {
  const overflowRef = useRef<HTMLDivElement>(null)
  const controlsRef = useRef<HTMLDivElement>(null)
  const arrowUpRef = useRef<HTMLButtonElement>(null)
  const arrowDownRef = useRef<HTMLButtonElement>(null)
  const arrowLeftRef = useRef<HTMLButtonElement>(null)
  const arrowRightRef = useRef<HTMLButtonElement>(null)

  function handleArrowUpClick () {
    if (overflowRef.current) {
      gsap.to(overflowRef.current, {
        scrollTop: overflowRef.current.scrollTop - scrollStep,
        ...scrollAnimation
      })
    }
  }

  function handleArrowDownClick () {
    if (overflowRef.current) {
      gsap.to(overflowRef.current, {
        scrollTop: overflowRef.current.scrollTop + scrollStep,
        ...scrollAnimation
      })
    }
  }

  function handleArrowLeftClick () {
    if (overflowRef.current) {
      gsap.to(overflowRef.current, {
        scrollLeft: overflowRef.current.scrollLeft - scrollStep,
        ...scrollAnimation
      })
    }
  }

  function handleArrowRightClick () {
    if (overflowRef.current) {
      gsap.to(overflowRef.current, {
        scrollLeft: overflowRef.current.scrollLeft + scrollStep,
        ...scrollAnimation
      })
    }
  }

  function handleOverflowScroll () {
    if (overflowRef.current) {
      const overflow = overflowRef.current

      if (mode === 'vertical') {
        const overflowHeight = overflow.scrollTop + overflow.offsetHeight

        arrowUpRef.current.disabled = overflow.scrollTop === 0;
        arrowDownRef.current.disabled = overflow.scrollHeight === overflowHeight;
      }

      if (mode === 'horizontal') {
        const overflowWidth = overflow.scrollLeft + overflow.offsetWidth

        arrowLeftRef.current.disabled = overflow.scrollLeft === 0;
        arrowRightRef.current.disabled = overflowRef.current.scrollWidth - overflowWidth <
          scrollLeftMargin;
      }
    }
  }

  function handleWindowResize () {
    if (overflowRef.current) {
      if (mode === 'vertical') {
        if (
          overflowRef.current.scrollHeight > overflowRef.current.offsetHeight
        ) {
          controlsRef.current.hidden = false
          overflowRef.current.classList.add('overflows');
        } else {
          controlsRef.current.hidden = true
          overflowRef.current.classList.remove('overflows');
        }
      }

      if (mode === 'horizontal') {
        controlsRef.current.hidden = overflowRef.current.scrollWidth <= overflowRef.current.offsetWidth;
      }

      handleOverflowScroll()
    }
  }

  useEffect(() => {
    const overflowContainer = overflowRef.current
    const throttledResizeHandler = throttle(handleWindowResize, 300)

    if (overflowContainer) {
      overflowContainer.addEventListener('scroll', handleOverflowScroll)
      handleOverflowScroll()
    }

    window.addEventListener('resize', throttledResizeHandler)
    handleWindowResize()

    return () => {
      if (overflowContainer) {
        overflowContainer.removeEventListener('scroll', handleOverflowScroll)
      }

      window.removeEventListener('resize', throttledResizeHandler)
    }

    // eslint-disable-next-line
  }, [])

  return (
    <Wrapper className={mode}>
      {mode === 'vertical' && (
        <Controls ref={controlsRef} className={mode}>
          <IconWrapper onClick={handleArrowUpClick} ref={arrowUpRef}>
            <Icon name='arrow-up' color='white' />
          </IconWrapper>
          <ControlsSeparator className={mode} />
          <IconWrapper onClick={handleArrowDownClick} ref={arrowDownRef}>
            <Icon name='arrow-down' color='white' />
          </IconWrapper>
        </Controls>
      )}
      <Overflow ref={overflowRef}>
        <Content className={mode}>{children}</Content>
      </Overflow>
      {mode === 'horizontal' && (
        <Controls ref={controlsRef} className='horizontal'>
          <IconWrapper onClick={handleArrowLeftClick} ref={arrowLeftRef}>
            <Icon name='arrow-left' color='white' />
          </IconWrapper>
          <ControlsSeparator className={mode} />
          <IconWrapper onClick={handleArrowRightClick} ref={arrowRightRef}>
            <Icon name='arrow-right' color='white' />
          </IconWrapper>
        </Controls>
      )}
    </Wrapper>
  )
}

Carousel.defaultProps = {
  mode: 'vertical'
}

export default Carousel
