import styled from 'styled-components'

export const CollapseOutputs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 568px;

  > * {
    margin-bottom: 28px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`

export default CollapseOutputs
