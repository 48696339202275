import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import BlockRole from './ui/blockRole/BlockRole'
import Carousel from './carousel'
import { eventBus } from '../app/events'
import Collapse from './ui/collapse/Collapse'
import CollapseOutputs from './ui/collapseOutputs/CollapseOutputs'
import BlockOutputsList from './ui/blockOutputsList/BlockOutputsList'
import { Media } from './Media'
import { YamlPage } from '@koii/interfaces'
import Tooltip from './ui/tooltip/Tooltip'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`

const Title = styled.h1`
  font-weight: 300;
  font-size: 40px;
  line-height: 50px;
  color: ${props => props.theme.colors.white};
  margin: 0;
`

const Description = styled.h4`
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.colors.gray1};
  margin: 20px 0 80px;
  max-width: 490px;
`

const BlockOutputs = styled.div``

function RolePage (props: YamlPage) {
  const { t } = useTranslation()

  useEffect(() => {
    eventBus.emit('pageOpened', props.name)
  }, [props.name])

  return (
    <Wrapper>
      <section>
        <Title>{t(`pages.role.title`)}</Title>
        <Description>
          <Trans
            components={{
              tooltip: <Tooltip />
            }}
          >
            {`content.${props.name}.description`}
          </Trans>
        </Description>
      </section>

      <Media lessThan='xl'>
        <CollapseOutputs>
          {props.outputs.map(output => (
            <Collapse
              key={output.url}
              title={t(t(`content.${output.name}.title`))}
              url={output.url}
              description={t(`content.${output.name}.description`)}
              iconName={output.icon}
              ctaButton={t(`pages.role.collapse`)}
            />
          ))}
        </CollapseOutputs>
      </Media>
      <BlockOutputs>
        <Media greaterThanOrEqual='xl'>
          <Carousel mode='horizontal'>
            <BlockOutputsList direction='row' gap={52}>
              {props.outputs.map(output => (
                <BlockRole
                  key={output.url}
                  title={t(t(`content.${output.name}.title`))}
                  url={output.url}
                  description={t(`content.${output.name}.description`)}
                  iconName={output.icon}
                  onMouseEnter={() =>
                    eventBus.emit('mouseEnteredBlock', output.name)
                  }
                  onMouseLeave={() =>
                    eventBus.emit('mouseLeftBlock', output.name)
                  }
                />
              ))}
            </BlockOutputsList>
          </Carousel>
        </Media>
      </BlockOutputs>
    </Wrapper>
  )
}

export default RolePage
