import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import BlockFeature from './ui/blockFeature/BlockFeature'
import { breakpoints } from './ui/breakpoints'
import Carousel from './carousel'
import { Link } from 'gatsby'
import { eventBus } from '../app/events'
import BlockOutputsList from './ui/blockOutputsList/BlockOutputsList'
import CollapseOutputs from './ui/collapseOutputs/CollapseOutputs'
import Collapse from './ui/collapse/Collapse'
import { Media } from './Media'
import { YamlPage } from '@koii/interfaces'
import Tooltip from './ui/tooltip/Tooltip'

const Wrapper = styled.div`
  padding-top: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  ${breakpoints.lg} {
    flex-direction: row;
  }
`

const Part = styled.div`
  margin-bottom: 60px;

  ${breakpoints.lg} {
    flex: 1;
    margin: 100px 0;
  }
`

const Title = styled.h1`
  font-weight: 300;
  font-size: 40px;
  line-height: 50px;
  color: ${props => props.theme.colors.white};
  margin: 0;
`

const Description = styled.h4`
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.colors.gray1};
  margin: 27px 0;
  max-width: 490px;
`

const BlockOutputs = styled.div`
  flex: 1;

  > div {
    width: 100%;
    height: 100%;
  }
`

function FeaturePage (props: YamlPage) {
  const { t } = useTranslation()

  useEffect(() => {
    eventBus.emit('pageOpened', props.name)
  }, [props.name])

  return (
    <Wrapper>
      <Part>
        <Title>{t(`content.${props.name}.title`)}</Title>
        <Description>
          <Trans
            components={{
              tooltip: <Tooltip />
            }}
          >
            {`content.${props.name}.description`}
          </Trans>
        </Description>
        <Media greaterThanOrEqual='xl'>
          <Link to='/explore' className='underline'>
            {t(`pages.feature.startExploring`)}
          </Link>
        </Media>
      </Part>

      <Media lessThan='xl'>
        <CollapseOutputs>
          {props.outputs.map(output => (
            <Collapse
              key={output.name}
              title={t(t(`content.${output.name}.title`))}
              url={output.url}
              description={t(`content.${output.name}.description`)}
              iconName={output.icon}
              ctaButton={t(`content.${output.name}.ctaButton`)}
            />
          ))}
        </CollapseOutputs>
      </Media>
      <BlockOutputs>
        <Media greaterThanOrEqual='xl'>
          <Carousel>
            <BlockOutputsList direction='column' gap={25}>
              {props.outputs.map(output => (
                <BlockFeature
                  key={output.name}
                  title={t(t(`content.${output.name}.title`))}
                  url={output.url}
                  description={t(`content.${output.name}.description`)}
                  iconName={output.icon}
                  ctaButton={t(`content.${output.name}.ctaButton`)}
                  onMouseEnter={() =>
                    eventBus.emit('mouseEnteredBlock', output.name)
                  }
                  onMouseLeave={() =>
                    eventBus.emit('mouseLeftBlock', output.name)
                  }
                />
              ))}
            </BlockOutputsList>
          </Carousel>
        </Media>
      </BlockOutputs>
    </Wrapper>
  )
}

export default FeaturePage
