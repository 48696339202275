import { SoundService } from '@koii/sound'
import Container from 'typedi'
import { useEvent } from '../events'
import { useStore } from '../store'

export function useToggleSound () {
  const [{ soundEnabled }, { setSoundEnabled }] = useStore()
  Container.set('soundEnabled', soundEnabled)

  useEvent('toggleSoundClicked', () => {
    try {
      const soundEnabled = Container.get<boolean>('soundEnabled')
      const soundService = Container.get<SoundService>('soundService')
      const backgroundLoop = soundService.getSound('backgroundLoop')

      setSoundEnabled(!soundEnabled)

      if (soundEnabled) {
        backgroundLoop.pause()
      } else {
        backgroundLoop.play()
      }
    } catch (e) {
      console.log('toggleSoundClicked error', e)
    }
  })
}
