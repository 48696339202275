import { Stage } from '@koii/stage'
import Container from 'typedi'
import { useEvent } from '../events'
import { useStore } from '../store'

export function usePageOpen () {
  const [{ soundEnabled }] = useStore()
  Container.set('soundEnabled', soundEnabled)

  useEvent('pageOpened', (name: string) => {
    try {
      const stage = Container.get<Stage>('stage')

      if (stage) {
        stage.setSelectedBlockByName(name)
      }
    } catch (e) {
      console.log('pageOpened error:', e)
    }
  })
}
