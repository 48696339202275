import React from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { breakpoints } from '../breakpoints'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import { ICrumb } from '@koii/interfaces'
interface BreadcrumbProps {
  crumbs: ICrumb[]
  direction: 'row' | 'column'
}

const Wrapper = styled.div`
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: flex-start;
  flex: 1 1 auto;
  min-width: 0;

  &.column {
    margin-top: 20px;
  }
`

const CrumbsWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  min-width: 0;

  &.row {
    flex-direction: row;
    flex-direction: row-reverse;
    align-items: center;
  }

  &.column {
    flex-direction: column;
    align-items: baseline;
  }
`
const Crumbs = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1 auto;
  min-width: 0;
`

const CrumbSeparator = styled.span`
  opacity: 0.5;
  margin: 0 10px;
`

const Crumb = styled.span`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.gray1};

  ${breakpoints.lg} {
    white-space: pre;
    display: inline;
    font-size: 20px;
    line-height: 25px;

    ${CrumbSeparator} {
      display: inline;
    }
  }

  &.highlighted {
    display: inline;
    color: ${props => props.theme.colors.white};
  }
`

const CrumbLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const HorizontalLine = styled.div`
  background: ${props => props.theme.colors.lightGreen};

  &.row {
    margin: 0 40px;
    height: 3px;
    width: 21px;
  }

  &.column {
    margin-top: 10px;
    width: 14px;
    height: 2px;
  }

  ${breakpoints.lg} {
  }
`

export function Breadcrumb (props: BreadcrumbProps) {
  const { t } = useTranslation()

  return (
    <Wrapper className={props.direction}>
      <CrumbsWrapper className={props.direction}>
        <Crumbs>
          {[...props.crumbs].map((crumb, index) => (
            <React.Fragment key={crumb.name}>
              <CrumbLink to={crumb.url}>
                <Crumb className={cx({ highlighted: index === 0 })}>
                  {i18next.exists(`content.${crumb.name}.breadcrumb`)
                    ? t(`content.${crumb.name}.breadcrumb`)
                    : t(`content.${crumb.name}.title`)}
                </Crumb>
              </CrumbLink>
              {index < props.crumbs.length - 1 ? (
                <CrumbSeparator>\</CrumbSeparator>
              ) : (
                ''
              )}
            </React.Fragment>
          ))}
        </Crumbs>
        <HorizontalLine className={props.direction} />
      </CrumbsWrapper>
    </Wrapper>
  )
}

export default Breadcrumb
