import { Howl, HowlOptions } from 'howler'

export class SoundService {
  private sounds = new Map<string, Howl>()

  addSound (name: string, config: HowlOptions) {
    this.sounds.set(name, new Howl(config))
  }

  getSound (name: string) {
    this.ensureSoundExists(name)
    return this.sounds.get(name)
  }

  private ensureSoundExists (name: string) {
    if (!this.sounds.has(name)) {
      throw Error(`Sound ${name} not found`)
    }
  }
}
