import styled from 'styled-components'
import Icon from '../icon/Icon'
import cx from 'classnames'
import { useTranslation } from 'react-i18next';
export interface SoundProps {
  enabled: boolean
  onClick: () => void
}

const Wrapper = styled.button`
  background-color: transparent;
  border: none;
  padding: 10px;
  margin: 0;
  display: block;
  cursor: pointer;
  opacity: 1;

  &.disabled {
    opacity: 0.7;
  }
`

export function Sound (props: SoundProps) {
  const { t } = useTranslation()

  return (
    <Wrapper
      onClick={props.onClick}
      className={cx({ disabled: !props.enabled })}
    >
      {props.enabled ? (
        <Icon name='volume1' color='lightGreen' title={t('ui.header.turnOffSound')} />
      ) : (
        <Icon name='volumeX' color='white' title={t('ui.header.turnOnSound')} />
      )}
    </Wrapper>
  )
}

export default Sound
