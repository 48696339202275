import styled from 'styled-components'
import { breakpoints } from '../breakpoints'

export interface LevelsProps {
  currentLevel: number
  maxLevel: number
}

const Wrapper = styled.div`
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: flex-start;
  height: 40px;

  ${breakpoints.lg} {
    height: 60px;
  }
`

const CurrentLevel = styled.span`
  font-size: 24px;
  line-height: 24px;

  ${breakpoints.lg} {
    font-size: 48px;
    line-height: 48px;
  }
`

const LevelSeparator = styled.span`
  margin: 0 6px;
`

const MaxLevel = styled.span`
  font-size: 12px;
  line-height: 15px;

  ${breakpoints.lg} {
    font-size: 20px;
    line-height: 25px;
  }
`

interface Levels {
  currentLevel: number
  maxLevel: number
}

export function getLevels (currentLevel: number, maxLevel: number): Levels {
  const minDisplayLevel = 0
  const maxDisplayLevel = 9

  return {
    currentLevel: Math.max(
      Math.min(currentLevel, maxDisplayLevel),
      minDisplayLevel
    ),
    maxLevel: Math.max(Math.min(maxLevel, maxDisplayLevel), minDisplayLevel)
  }
}

export function Levels (props: LevelsProps) {
  const { currentLevel, maxLevel } = getLevels(
    props.currentLevel,
    props.maxLevel
  )

  return (
    <Wrapper>
      <CurrentLevel>0{currentLevel}</CurrentLevel>
      <MaxLevel>
        <LevelSeparator>\</LevelSeparator>0{maxLevel}
      </MaxLevel>
    </Wrapper>
  )
}

export default Levels
