import Icon, { IconName } from '../icon/Icon'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

export interface BlockFloatingProps {
  title: string
  url: string
  description: string
  iconName: IconName
}

const Background = styled.div`
  background: ${props => props.theme.colors.black60};
  position: absolute;
  width: 85%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;
  transition: width 0.8s cubic-bezier(0.83, 0, 0.17, 1);
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  min-width: 518px;
  max-width: 618px;
  padding: 45px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Title = styled.h3`
  font-size: 28px;
  line-height: 35px;
  font-weight: 300;
  margin: 0 15px 15px 0;
  color: ${props => props.theme.colors.white};

  a {
    font-size: inherit;
  }

  a:hover {
    color: inherit;
  }
`

const Text = styled.div``

const RightPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const IconPart = styled.div`
  background: ${props => props.theme.colors.lightGreen};
  width: 80px;
  height: 80px;
  padding: 16px;
  box-sizing: border-box;
`

const Wrapper = styled.div`
  position: relative;
  pointer-events: all;
  max-width: 618px;
  height: inherit;

  > a {
    display: block;
  }

  &:hover ${Background} {
    width: 88%;
  }
`

export function BlockFloating (props: BlockFloatingProps) {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Link to={props.url}>
        <Content>
          <Text>
            <Title>{props.title}</Title>

            <span className='underline'>{t('ui.floatingBlock.learnMore')}</span>
          </Text>
          <RightPart>
            <IconPart>
              <Icon name={props.iconName} color='darkBlue' />
            </IconPart>
          </RightPart>
        </Content>
      </Link>
      <Background />
    </Wrapper>
  )
}

export default BlockFloating
