import { Link } from 'gatsby'
import styled from 'styled-components'
import { Media } from '../../Media'
import Icon from '../icon/Icon'
import { useTranslation } from 'react-i18next'
import Box from '../box/Box'

export interface FooterProps {
  showGoToBeginning: boolean
}

const Wrapper = styled.footer`
  padding: 20px;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.theme.colors.white};
  pointer-events: auto;

  &.narrow-padding {
    padding: 20px 40px;
  }

  &.wide-padding {
    padding: 40px;
  }
`

const Icons = styled.div`
  display: flex;

  > * {
    margin-right: 25px;
  }

  > *:last-child {
    margin-right: 0;
  }

  svg {
    height: 21px;
    transition: color 0.3s ease-in;
  }

  svg:hover {
    color: ${props => props.theme.colors.lightGreen};
  }

  &.with-margin {
    margin-right: 42px;
  }
`

const Copyright = styled.div`
  &.with-margin {
    text-align: left;
    margin-bottom: 26px;
  }
`

const Separator = styled.div`
  align-self: stretch;
  width: 1px;
  margin: 0 26px;
  background: ${props => props.theme.colors.white};
`

function IconsWrapper ({ className }: { className?: string }) {
  const { t } = useTranslation()

  return (
    <Icons className={className}>
      <a
        href='https://twitter.com/KoiiNetwork'
        target='_blank'
        rel='noreferrer'
      >
        <Icon name='twitter' color='white' title={t('ui.footer.twitterLogo')} />
      </a>
      <a href='https://discord.gg/koii' target='_blank' rel='noreferrer'>
        <Icon name='discord' color='white' title={t('ui.footer.discordLogo')} />
      </a>
      <a href='https://t.me/koiinetwork' target='_blank' rel='noreferrer'>
        <Icon name='telegram' color='white' title={t('ui.footer.telegramLogo')} />
      </a>
      <a
        href='https://github.com/koii-network/'
        target='_blank'
        rel='noreferrer'
      >
        <Icon name='github' color='white' title={t('ui.footer.githubLogo')} />
      </a>
    </Icons>
  )
}

function PrivacyLink () {
  const { t } = useTranslation()

  return (
    <a
      href='https://koii.network/Koi%20Labs%20Inc.%20Privacy%20Policy.pdf'
      className='underline'
    >
      {t('ui.footer.privacyPolicy')}
    </a>
  )
}

function GoToBeginingLink () {
  const { t } = useTranslation()

  return (
    <Link to='/' className='underline'>
      {t('ui.common.goToBeginning')}
    </Link>
  )
}

export function Footer (props: FooterProps) {
  const { t } = useTranslation()

  return (
    <>
      <Media lessThan='md'>
        <Wrapper>
          <Copyright className='with-margin'>
            {t('ui.footer.copyright')}
          </Copyright>
          <Box display='flex' flexDirection='row'>
            <IconsWrapper />
            <Separator />
            <PrivacyLink />
          </Box>
        </Wrapper>
      </Media>
      <Media at='md'>
        <Wrapper className='wide-padding'>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <IconsWrapper className='with-margin' />
            <Copyright>{t('ui.footer.copyright')}</Copyright>
            <Separator />
            <PrivacyLink />
          </Box>
        </Wrapper>
      </Media>
      <Media between={['lg', 'xl']}>
        <Wrapper className='narrow-padding'>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <IconsWrapper className='with-margin' />
              <Copyright>{t('ui.footer.copyright')}</Copyright>
              <Separator />
              <PrivacyLink />
            </Box>
            {props.showGoToBeginning && <GoToBeginingLink />}
          </Box>
        </Wrapper>
      </Media>
      <Media greaterThanOrEqual='xl'>
        <Wrapper className='wide-padding'>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <IconsWrapper className='with-margin' />
              <Copyright>{t('ui.footer.copyright')}</Copyright>
              <Separator />
              <PrivacyLink />
            </Box>
            {props.showGoToBeginning && <GoToBeginingLink />}
          </Box>
        </Wrapper>
      </Media>
    </>
  )
}

Footer.defaultProps = {
  showGoToBeginning: true
}

export default Footer
