import { Container } from 'typedi'
import { SoundService } from '@koii/sound'

const soundService = new SoundService()
Container.set<SoundService>('soundService', soundService)

soundService.addSound('blockHovered', {
  src: ['click-2.wav'],
  volume: 0.025
})
soundService.addSound('backgroundLoop', {
  src: ['ambient-loop-1.mp3'],
  loop: true,
  volume: 0.5,
  autoplay: true
})

export default function SoundDependencies () {
  return null
}
