import { useTranslation } from 'react-i18next'
import { useStore } from '../app/store'
import SEO from '../components/seo'
import BlockFloating from '../components/ui/blockFloating/BlockFloating'
import BlockLegend from '../components/ui/blockLegend/BlockLegend'

function ExplorePage () {
  const { t } = useTranslation()
  const [{ floatingBlockData }] = useStore()
  const { name, url, icon } = floatingBlockData || {}

  return (
    <>
      <SEO
        title={t('pages.explore.title')}
        description={t('pages.explore.description')}
      />
      {floatingBlockData && (
        <BlockFloating
          title={t(`content.${name}.title`)}
          description={t(`content.${name}.description`)}
          iconName={icon}
          url={url}
        />
      )}

      <BlockLegend />
    </>
  )
}

export default ExplorePage
