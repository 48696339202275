import Icon, { IconName } from '../icon/Icon'
import styled from 'styled-components'
import { Link } from 'gatsby'

export interface BlockChoiceProps {
  title: string
  url: string
  description: string
  iconName: IconName
  onMouseEnter: () => void
  onMouseLeave: () => void
}

const Background = styled.div`
  background: ${props => props.theme.colors.black60};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  right: 0;
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 30px;
`
const TitleWrapper = styled.div`
  transition: all 0.5s ease-in-out;
  display: inline-block;

  &.move-up {
    transform: translateY(50%);
  }
`

const Title = styled.h3`
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  margin: 0;

  a {
    font-size: inherit;
  }

  a:hover {
    color: inherit;
  }
`

const TitleFirstLine = styled.div`
  display: flex;
  flex-direction: row;
`

const TitleLine = styled.div`
  margin-top: 10px;
  height: 2px;
  background: ${props => props.theme.colors.white};
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.3s;
  opacity: 0;
  width: 0%;
`

const Description = styled.h4`
  font-size: 16px;
  line-height: 150%;
  font-weight: 300;
  color: ${props => props.theme.colors.gray1};
  opacity: 0;
  margin-bottom: 0;
  transition: all 0.3s ease-in-out;
`
const Text = styled.div`
  position: relative;
  z-index: 1;
`

const RightPart = styled.div`
  margin-right: 30px;
`

const IconPart = styled.div`
  background: ${props => props.theme.colors.lightGreen};
  width: 64px;
  height: 64px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Arrow = styled.div`
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  height: 23px;
  display: inline-block;
  margin-left: 15px;
`

const Wrapper = styled.div`
  position: relative;

  a {
    display: inline-block;
    width: 100%;
  }

  &:hover ${Description} {
    opacity: 1;
  }

  &:hover ${TitleWrapper} {
    color: ${props => props.theme.colors.white};
  }

  &:hover ${TitleWrapper}.move-up {
    transform: translateY(0);
  }

  &:hover ${TitleLine} {
    opacity: 1;
    width: 100%;
  }

  &:hover ${Arrow} {
    opacity: 1;
  }
`

export function BlockChoice (props: BlockChoiceProps) {
  return (
    <Wrapper
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <Link to={props.url}>
        <Content>
          <RightPart>
            <IconPart>
              <Icon name={props.iconName} color='darkBlue' />
            </IconPart>
          </RightPart>
          <Text>
            <TitleWrapper className={props.description ? 'move-up' : ''}>
              <TitleFirstLine>
                <Title>{props.title}</Title>
                <Arrow>
                  <Icon name='arrow-right' color='white' />
                </Arrow>
              </TitleFirstLine>
              <TitleLine />
            </TitleWrapper>
            {props.description && (
              <Description>{props.description}</Description>
            )}
          </Text>
        </Content>
      </Link>
      <Background />
    </Wrapper>
  )
}

export default BlockChoice
