import React, { useEffect } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { eventBus } from '../app/events'

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
  cursor: grab;
`

export function StageComponent () {
  const result = useStaticQuery(graphql`
    query {
      allBlocksYaml {
        nodes {
          name
          type
          url
          icon
        }
      }
    }
  `)

  useEffect(() => {
    eventBus.emit('stageComponentLoaded', result.allBlocksYaml.nodes)

    // eslint-disable-next-line
  }, [])

  return <Canvas id='stage' />
}

export default React.memo(StageComponent)
